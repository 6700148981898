.container {
    margin: 0 auto;
    @media #{$screen-lg} {
        max-width: 1120px;
        width: 100%;
    }
    @media (min-width: 991px) and (max-width: 1200px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    @media (max-width: 992px) {
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
    @media (min-width: $screen-md-max) {
        padding-left: 20px;
        padding-right: 20px;
    }
    @media #{$screen-xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
}