.gallery {
    background: #141414;
    padding: 72px 0px 80px 0px;
    .gallery__title {
        font-weight: 700;
        font-size: 32px;
        line-height: 32px;
        color: #ffffff;
        margin-bottom: 80px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        &:before {
            position: absolute;
            width: 90%;
            height: 1px;
            background: #fff;
            content: "";
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 0;
        }
        span {
            background: #141414;
            z-index: 1;
            padding: 0 100px 0 0px;
        }
        @media (max-width: 768px) {
            span {
                padding: 0 20px;
            }
        }
    }
    .gallery__slide {
        border-radius: 12px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        img {
            width: 100%;
            max-height: 360px;
        }
    }
    .gallery__arrows {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 57px;
        .gallery__arrow_prev {
            img {
                transform: rotate(-180deg);
            }
            margin-right: 20px;
        }
        .gallery__arrow {
            width: 80px;
            height: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            border: 1px solid #fff;
            cursor: pointer;
            &:hover {
                border-color: #ff671c;
            }
        }
        display: none;
        @media (max-width: 768px) {
            display: flex;
        }
    }
    &_light {
        background: #fff;
        .gallery__title {
            color: #252523;
            span {
                background: #fff;
            }
            &:before {
                background: #252523;
            }
        }
        .gallery__arrows {
            display: flex;
            .gallery__arrow {
                border: 1px solid #252523;
            }
        }
    }
}