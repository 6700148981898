.footer {
    padding: 50px 0 50px 0px;
    background: #141414;
    border-top: 1px solid #fff;
    .footer__content {
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        display: grid;
        grid-template-areas: 'socials . contacts logo';
        grid-template-columns: auto 30px 1fr auto;
        align-items: center;
        .footer__socials {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: 1px solid #6a6c73;
            border-radius: 8px;
            padding: 19px 14px;
            grid-area: socials;
            .footer__social-link {
                margin-bottom: 30px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            @media (max-width: 991px) {
                flex-direction: row;
                align-items: center;
                display: inline-flex;
                align-self: center;
                width: 90px;
                padding: 14px;
                .footer__social-link {
                    margin-bottom: 0;
                    margin-right: 18px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            // @media (max-width: 768px) {
            //     margin: 0 auto;
            // }
        }
        @media (max-width: 991px) {
            display: grid;
            grid-template-areas: "socials logo" "contacts contacts";
            justify-content: center;
            row-gap: 34px;
            column-gap: 50px;
            grid-template-columns: 1fr;
        }
        @media (max-width: 600px) {
            grid-template-areas: "logo" "subscribe" "contacts" "socials";
            justify-content: center;
        }
    }
    .footer__subscribe {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 320px;
        grid-area: subscribe;
        p {
            color: #fff;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            &.mb-2 {
                margin-bottom: 30px;
            }
        }
        form {
            width: 100%;
            position: relative;
            margin-bottom: 30px;
        }
        span {
            color: #fff;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            &:first-child {
                margin-bottom: 10px;
            }
        }
        .input-group {
            display: flex;
            width: 100%;
            input {
                background: none;
                width: 100%;
                border: none;
                border-bottom: 1px solid #fff;
                padding-left: 0;
                padding-bottom: 16px;
                padding-top: 16px;
                padding-right: 0;
            }
        }
        button {
            background: none;
            border: none;
            position: absolute;
            right: 0;
            bottom: 8px;
            img {
                width: 14px;
                height: 16px;
            }
        }
        @media (max-width: 991px) {
            align-self: flex-start;
        }
    }
    .footer__contacts {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        grid-area: contacts;
        .footer__contacts-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-transform: uppercase;
            margin-bottom: 24px;
            span {
                color: #fff;
            }
            .footer__contact-item-icon {
                margin-right: 16px;
                border: 1px solid #6a6c73;
                border-radius: 8px;
                padding: 10px 10px 8px 10px;
            }
            &:hover {
                .footer__contact-item-icon {
                    background: rgba(255, 255, 255, 0.2);
                }
            }
            @media (max-width: 768px) {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .footer__copyright {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 0;
            color: #fff;
            &.mb-2 {
                margin-bottom: 20px;
            }
        }
    }
    .footer__logo {
        max-width: 200px;
        width: 100%;
        grid-area: logo;
        @media (max-width: 991px) {
            max-width: 100px;
        }
    }
}

// .footer__row {
//     display: flex;
//     align-items: center;
//     .footer__socials {
//         margin-right: 30px;
//     }
// }