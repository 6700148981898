.mainSlider_slider {
    background: none;
    position: relative;
    &:before {
        display: none;
    }
    .mainSlider__block {
        .mainSlider__image {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
        .mainSlider__slide-wrapper {
            height: 300px !important;
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, .6);
            }
            .mainSlider__heading {
                position: relative;
                z-index: 2;
                color: #fff;
                font-size: 56px;
                line-height: 62px;
                @media (max-width: 768px) {
                    font-size: 32px;
                    line-height: 40px;
                }
            }
            .mainSlider__text {
                position: relative;
                z-index: 2;
                color: #fff;
                font-size: 35px;
                line-height: 44px;
                width: 60%;
                text-align: center;
                @media (max-width: 768px) {
                    font-size: 24px;
                    line-height: 30px;
                }
            }
        }
    }
    .swiper-slide {
        // width: 100vw !important;
    }
    .swiper-button-prev,
    .swiper-button-next {
        padding: 10px 20px;
        // border: 2px solid #fff;
        &:before,
        &:after {
            color: #fff;
        }
    }
}