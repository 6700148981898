@import "../../../assets/css/variables/colors";
@import "../../../assets/css/mixins/flex";
.promo {
    // background: url("../../../assets/images/photo4.jpg");
    background: #141414;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    padding: 0 0px 0px;
    min-height: 300px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #fff;
    @media (min-width: 768px) and (max-width: 1050px) {
        margin-top: 0;
    }
    .container {
        height: inherit;
    }
    .promo__content {
        height: inherit;
    }
    position: relative;
    &:before {
        // content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .8);
        left: 0;
        top: 0;
        z-index: 2;
        pointer-events: none
    }
    .promo__content {
        display: flex;
        z-index: 4;
        position: relative;
        flex-direction: column;
        align-items: center;
        .promo__image {
            max-width: 400px;
            @media (max-width: 768px) {
                margin-bottom: 0;
                width: 100%;
            }
            // filter: invert(1);
        }
        .promo__text {
            color: #fff;
            font-size: 22px;
            line-height: 32px;
            width: 50%;
            text-align: center;
        }
        @media (max-width: 768px) {
            flex-direction: column;
            padding: 100px 0px;
            .promo__text {
                margin-top: 50px;
                width: 100%;
                font-size: 18px;
                line-height: 22px;
                font-weight: 300;
                text-align: center;
            }
        }
    }
    .slick__list {
        padding: 0 0 !important;
    }
    // @media (min-width: 1450px) {
    //     .cover {
    //         object-fit: cover !important;
    //     }
    // }
    .promo__slide {
        display: flex !important;
        justify-content: center;
        align-items: center;
        height: 700px;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        video {
            @media (min-width: 991px) {
                object-fit: cover;
            }
        }
        @media (min-width: 991px) {
            .promo__link {
                position: absolute;
                height: 350px;
                width: 350px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    @media (max-width: 768px) {
        height: 100%;
        min-height: 100%;
    }
}

.promo__arrow {
    position: absolute;
}