.btn {
    padding: 9px 26px;
    font-size: 16px;
    line-height: 22px;
    border-radius: 6px;
    &_primary {
        background: $primary;
        color: $white;
        box-shadow: 0px 4px 8px #FFDFD5;
    }
}