@import "../../../node_modules/reset-css/reset.css";
@import "./variables/screen";
@import "./variables/main";
@import "./variables/colors";
@import "./mixins/flex";
@import "./components/container";
@import "./components/btn";
@import "./components/link";
// @import "../../../node_modules/slick-carousel/slick/slick.css";
// @import "../../../node_modules/slick-carousel/slick/slick-theme.css";
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Martel+Sans:wght@300;400;600;700&display=swap');
* {
    font-family: 'Martel Sans', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
}

body,
.page {
    overflow-x: hidden
}